import React from 'react';

export default function SavingSpinner(props) {
  return (
    <>
      <div className='lds-heart'>
        <div></div>
      </div>
    </>
  );
}
